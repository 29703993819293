export default {
    name: 'LeftBar',
    mixins: [require('../../../mixins/humanize')],
    props: {
        lot: Object,
    },
    components: {
        Manager: require('../Common/Manager/index.vue').default,
        Requests: require('../Main/Requests/index.vue').default,
    },
    computed: {
        price() {
            const { price: { USD, EUR, UAH } } = this.lot;
            return {
                USD: this.humanizeNumber(USD),
                EUR: this.humanizeNumber(EUR),
                UAH: this.humanizeNumber(UAH),
            };
        },
        city() {
            return this.lot.city || {};
        },
        state() {
            return this.lot.state || {};
        },
        isCenter() {
            return this.city.id === this.state.id;
        }
    },
    methods: {},
    i18n: {
        message: {
            ru: {
                'тыс. км пробег': 'тыс. км пробег',
            },
            uk: {
                'тыс. км пробег': 'тис. км пробіг',
            }
        }
    }
}