import { mapMutations } from "vuex";

export default {
    name: 'Classification',
    props: {
        report: Object,
    },
    methods: {
        ...mapMutations({ loggerData: 'marketplace/loggerData' }),
        onClassificationItemClick(clickAction) {
            this.loggerData({
                event_id: 868,
                click_action: clickAction
            });
        }
    },
    computed: {
        valuations() {
            return this.report.valuations;
        },
        body() {
            return this.valuations[this.report.body.valuation];
        },
        technical() {
            return this.valuations[this.report.technical.valuation];
        },
        interior() {
            return this.valuations[this.report.interior.valuation];
        },
        testDrive() {
            return this.valuations[this.report.testDrive.valuation];
        },
    },
    i18n: {
        messages: {
            ru: {
                'Состояние': 'Состояние',
                'салона': 'салона',
                'поездка': 'поездка',
                'Тестовая': 'Тестовая',
                'Техническое': 'Техническое',
                'кузова': 'кузова'
            },
            uk: {
                'Состояние': 'Стан',
                'салона': 'салону',
                'поездка': 'поїздка',
                'Тестовая': 'Тестова',
                'Техническое': 'Технічний',
                'кузова': 'кузова'
            }
        }
    }
}
