import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'InnerPopup',
    data() {
        return {
            name: '',
            phone: '',
        }
    },
    components: {
        Popup: () => import('../../../Common/Popup.vue'),
    },
    computed: {
        ...mapGetters({
            info: 'marketplace/requestPopup',
            lot: 'marketplace/lot',
            getPopupData: 'marketplace/popupData'
        }),
        requested() {
            return this.lot.requests[this.info.type]
        }
    },
    methods: {
        ...mapMutations({
            setPopup: 'marketplace/requestPopup',
            requestStatus: 'marketplace/requestStatus',
            loggerData: 'marketplace/loggerData',
            popupData: 'marketplace/popupData'
        }),
        ...mapActions({
            addRequest: 'marketplace/addRequest',
        }),
        close() {
            this.setPopup({ title: '', btnText: '', type: '' });
            this.loggerData({
                event_id: 868,
                click_action: 4,
                ...this.getPopupData
            });
            this.popupData({});
        },
        submit(data) {
            this
                .addRequest({
                    name: this.name,
                    phone: this.phone,
                    type: this.info.type,
                })
                .then(() => {
                    this.requestStatus({ name: this.info.type, status: true });
                    this.loggerData({
                        event_id: 868,
                        click_action: 3,
                        ...this.getPopupData
                    });
                })
                // .finally(() => {
                //     this.close();
                // })
        },
        onChange({ target }) {
            const { name } = target || {};
            this.loggerData({
                event_id: 868,
                click_action: name === 'name' ? 1 : 2,
                ...this.getPopupData
            });
        }
    },
    i18n: {
        messages: {
            ru: {
                'Политике приватности': 'Политике приватности',
                'Ваши персональные данные будут обработаны и защищены согласно': 'Ваши персональные данные будут обработаны и защищены согласно',
                'Вернуться к предложению': 'Вернуться к предложению',
                'В течение 10 минут вам позвонит менеджер': 'В течение 10 минут вам позвонит менеджер',
                'Ваша заявка принята': 'Ваша заявка принята',
                'Ожидайте звонка менеджера для подтверждения заявки': 'Ожидайте звонка менеджера для подтверждения заявки',
                'Введите свое имя': 'Введите свое имя',
                'Введите телефон': 'Введите телефон',
            },
            uk: {
                'Политике приватности': 'Політики приватності',
                'Ваши персональные данные будут обработаны и защищены согласно': 'Ваші персональні дані будуть оброблені та захищені згідно до',
                'Вернуться к предложению': 'Повернутись до пропозиції',
                'В течение 10 минут вам позвонит менеджер': 'Протягом 10 хвилин вам зателефонує менеджер',
                'Ваша заявка принята': 'Вашу заявку прийнято',
                'Ожидайте звонка менеджера для подтверждения заявки': 'Чекайте на дзвінок менеджера для підтвердження заявки',
                'Введите свое имя': `Введіть своє ім'я`,
                'Введите телефон': `Введіть телефон`,
            }
        }
    }
}
