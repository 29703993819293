import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
    name: 'Main',
    mixins: [require('../../../mixins/humanize')],
    components: {
        Photo: require('./Gallery/index.vue').default,
        DigitalPassport: require('./DigitalPassport/index.vue').default,
        Manager: require('../Common/Manager/index.vue').default,
        Label: require('../Common/Label/index.vue').default,
        Description: require('../Common/Description/index.vue').default,
        Guarantees: require('./Guarantees/index.vue').default,
        Seller: require('./Seller/index.vue').default,
        Report: require('./Report/index.vue').default,
        Requests: require('./Requests/index.vue').default
    },
    data() {
        return {
            report: null,
        }
    },
    computed: {
        ...mapGetters({
            lot: 'marketplace/lot',
            activeBookmark: 'marketplace/bookmarks/active',
        }),
        createdDate() {
            return moment(this.lot.meta.date).format('L');
        }
    },
    methods: {
        ...mapActions({
            getReport: 'marketplace/report',
            addBookmarks: 'marketplace/bookmarks/add',
            deleteBookmarks: 'marketplace/bookmarks/delete',
            isActiveBookmark: 'marketplace/bookmarks/isActive',
        }),
        ...mapMutations({
            loggerData: 'marketplace/loggerData'
        }),
        subscribe(event) {
            if (this.activeBookmark) {
                this.deleteBookmarks();
            } else {
                this.addBookmarks();
                this.loggerData({ event_id: 351 });
            }
        },
    },
    mounted() {
        const { order: { id = 0, hash } = {} } = this.lot || {};
        if (id) {
            this
                .getReport({ id, hash })
                .then((report) => {
                    this.report = report;
                });
        }
        this.isActiveBookmark(this.lot.id);
    },
    i18n: {
        messages: {
            ru: {
                'тыс. км': 'тыс. км',
                'Добавить объявление в избранное': 'Добавить объявление в избранное',
                'пробег состоянием на': 'пробег состоянием на'
            },
            uk: {
                'тыс. км': 'тис. км',
                'Добавить объявление в избранное': 'Додати оголошення в обране',
                'пробег состоянием на': 'пробіг станом на'
            }
        }
    }
}
