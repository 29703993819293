import { mapMutations, mapGetters } from 'vuex';

export default {
    name: 'Body',
    data() {
        return {
            megaPhoto: false,
            blockId: 0,
            id: '',
        }
    },
    props: {
        report: Object,
        valuations: Object,
    },
    components: {
        BlockTitle: require('../Common/BlockTitle/index.vue').default,
        MegaPhoto: require('../../Gallery/MegaPhoto/index.vue').default,
    },
    computed: {
        ...mapGetters({
            lot: 'marketplace/lot',
        }),
        selected(){
            return Number(this.id.split('-')[1]);
        },
        valuation() {
            return this.valuations[this.report.valuation]
        },
        parts() {
            return Object
                .entries(this.report.parts)
                .map(([id, value]) => ({
                    ...value, id,
                    photos: this.isDesktop ? value.photos.slice(0, 2) : value.photos,
                    photosOrig: value.photos,
                }));
        },
        showAuto() {
            return this.parts.some(({ valuation }) => valuation !== 'A');
        },
        damagedParts() {
            return this.parts.filter(({ valuation }) => valuation !== 'A');
        },
        photos() {
            if (!this.blockId) {
                return [];
            }
            return this
                .report
                .parts[this.blockId]
                .photos
                .map((id) => ({ name: 'PHOTO', isAutoTest: true, id, link: this.photo(id)}));
        }
    },
    methods: {
        ...mapMutations({
            setPopup: 'marketplace/popup'
        }),
        scrollTo({ target }) {
            const [, id] = target.href.split('#');
            const element = document.getElementById(id);

            this.id = id;
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
        },
        photo(id) {
            const str = String(id);
            return `https://cdn.riastatic.com/photos/auto/autotest/${str.slice(0, 2)}/${str.slice(0, 4)}/${str}/${str}`;
        },
        openMegaPhoto(id) {
            this.blockId = id;
            this.setPopup(true);
            this.megaPhoto = true;
        },
        megaPhotoChangeState(value) {
            this.setPopup(value);
            this.megaPhoto = value;
            this.blockId = 0;
        }
    },
    i18n: {
        messages: {
            ru: {
                'Состояние кузова': 'Состояние кузова',
                'Фотоотчет повреждений авто от сертифицированного AUTO.RIA центра технических проверок': 'Фотоотчет повреждений авто от сертифицированного AUTO.RIA центра технических проверок',
                'Все исправно': 'Все исправно',
                'Смотреть еще': 'Смотреть еще',
            },
            uk: {
                'Смотреть еще': 'Дивитись ще',
                'Состояние кузова': 'Стан кузова',
                'Фотоотчет повреждений авто от сертифицированного AUTO.RIA центра технических проверок': 'Фотозвіт пошкоджень авто від сертифікованого AUTO.RIA центру технічних перевірок',
                'Все исправно': 'Все справно',
            }
        }
    }
}