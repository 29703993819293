export default {
    name: 'BlockTitle',
    props: {
        title: String,
        valuation: Object,
    },
    i18n: {
        messages: {
            ru: {
                A: 'отлично',
                B: 'хорошо',
                C: 'детали, требующие внимания',
                D: 'есть замечания',
            },
            uk: {
                A: 'відмінно',
                B: 'добре',
                C: 'деталі, варті уваги',
                D: 'є зауваження',
            }
        }
    }
}