import { mapActions } from 'vuex';

export default {
    name: 'DigitalPassport',
    props: {
        plate: String,
        vin: String,
    },
    data() {
        return {
            info: {}
        }
    },
    created() {
        this
            .passport(this.vin)
            .then((payload) => {
                this.info = payload;
            })
    },
    methods: {
        ...mapActions({
            passport: 'marketplace/passport',
        })
    },
    computed: {
        ready() {
            return Object.keys(this.info).length;
        },
        city() {
            const { city = {}, region = {} } = this.info;
            let text = city.name;
            if (city.id !== region.id) {
                text = `${region.name} ${this.$t('обл')}, ${text}`
            }
            return text;
        },
        plateStr() {
            if (this.plate) {
                const start = this.plate.slice(0, 2);
                const num = this.plate.slice(2, 6);
                const end = this.plate.slice(6, 8);
                return `${start} ${num} ${end}`;
            }
            return '';
        }
    },
    i18n: {
        messages: {
            ru: {
                'AUTO.RIA сверил данные с открытыми реестрами МВД': 'AUTO.RIA сверил данные с открытыми реестрами МВД',
                'Цифровой паспорт': 'Цифровой паспорт',
                'марка, модель, год': 'марка, модель, год',
                'номер кузова': 'номер кузова',
                'коробка передач': 'коробка передач',
                'цвет': 'цвет',
                'количество владельцев': 'количество владельцев',
                'город регистрации': 'город регистрации',
                'топливо, объем': 'топливо, объем',
                'обл': 'обл.',
            },
            uk: {
                'AUTO.RIA сверил данные с открытыми реестрами МВД': 'AUTO.RIA звірив дані з відкритими реєстрами МВС',
                'Цифровой паспорт': 'Цифровий паспорт',
                'марка, модель, год': 'марка, модель, рік',
                'номер кузова': 'номер кузова',
                'коробка передач': 'коробка передач',
                'цвет': 'колір',
                'количество владельцев': 'кількість власників',
                'город регистрации': 'місто реєстрації',
                'топливо, объем': 'пальне, об`єм',
                'обл': 'обл.',
            }
        }
    }
}