export default {
	name: 'Description',
	props: {
		data: Object,
	},
	data() {
		return {
			showFullDesc: false,
			descriptionHeight: 0,
		};
	},
	mounted() {
		this.descriptionHeight = this.$refs.description.offsetHeight;
	},
	computed: {
		btnText() {
			return this.showFullDesc ? this.$t('Скрыть') : this.$t('Читать еще');
		},
	},
	i18n: {
		messages: {
			ru: {
				'Читать еще': 'Читать еще',
				'Скрыть': 'Скрыть',
			},
			uk: {
				'Читать еще': 'Читати ще',
				'Скрыть': 'Сховати'
			}
		}
	}
};
