import { mapMutations } from 'vuex';

export default {
    name: 'Photo',
    data() {
        return {
            cursor: 0,
            soldOut: false,
            megaPhoto: false,
        }
    },
    props: {
        lot: Object,
    },
    methods: {
        ...mapMutations({
            setPopup: 'marketplace/popup'
        }),
        changeCursor(value) {
            this.cursor = value;
        },
        megaPhotoChangeState(value) {
            this.setPopup(value);
            this.megaPhoto = value;
        }
    },
    components: {
        ViewZone: require('./ViewZone/index.vue').default,
        Preview: require('./Preview/index.vue').default,
        MegaPhoto: () => import('./MegaPhoto/index.vue'),
    }
}