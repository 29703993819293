export default {
	name: 'Label',
	props: {
		name: String,
		value: {
			validator(item) {
				return Array.isArray(item) || typeof item === 'string';
			}
		},
		type: String,
		hex: String,
	},
	computed: {
	    isArray() {
	        return Array.isArray(this.value);
		}
	}
};
