import { mapMutations } from 'vuex';

const PLACEHOLDER = 'ххх хх хх';

export default {
    name: 'MegaPhotoManager',
    props: {
        info: Object,
        city: Object,
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        ...mapMutations({
            loggerData: 'marketplace/loggerData'
        }),
        showNumber() {
            this.loggerData({
                event_id: this.isMobile ? 334 : 21,
                phone_position: this.isMobile ? 11 : 2
            });
            this.show = true;
        }
    },
    computed: {
        number() {
            // (063) ххх хх хх
            const { phone: { formatted } } = this.info;
            if (this.show) return formatted;
            return formatted.replace(/\d{3} \d{2} \d{2}/gm, PLACEHOLDER);
        },
    },
    i18n: {
        messages: {
            ru: {
                'показать': 'показать',
            },
            uk: {
                'показать': 'показати',
            }
        },
    },
}
