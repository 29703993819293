import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'Requests',
    props: {
        simple: Boolean,
    },
    data() {
        return {
            'trade-in': {
                title: this.$t('Обменять по Trade-in'),
                btnText: this.$t('Запись на обмен'),
                type: 'trade-in',
            },
            reserve: {
                title: this.$t('Забронируйте свой будущий автомобиль прямо сейчас'),
                btnText: this.$t('Забронировать на 24 часа'),
                type: 'reserve',
            },
            'test-drive': {
                title: this.$t('Запись на тест-драйв'),
                btnText: this.$t('Запись на тест-драйв'),
                type: 'test-drive',
            }
        }
    },
    computed: {
        ...mapGetters({
            getPopupData: 'marketplace/popupData'
        }),
    },
    methods: {
        ...mapMutations({
            setPopup: 'marketplace/requestPopup',
            popupData: 'marketplace/popupData',
            loggerData: 'marketplace/loggerData'
        }),
        defineBlockTypeAction({ blockType, actionBlock }) {
            this.popupData({
                block_type: blockType,
                action_block: actionBlock
            });
            this.loggerData({
                event_id: 868,
                ...this.getPopupData
            });
        },
        open({ target }) {
            const { name } = target || {};
            this.setPopup(this[name]);
        }
    },
    i18n: {
        messages: {
            ru: {
                'Записаться': 'Записаться',
                'Забронировать': 'Забронировать',
                'Запись на тест-драйв': 'Запись на тест-драйв',
                'Забронировать на 24 часа': 'Забронировать на 24 часа',
                'Забронируйте свой будущий автомобиль прямо сейчас': 'Забронируйте свой будущий автомобиль прямо сейчас',
                'Запись на обмен': 'Запись на обмен',
                'Обменять по Trade-in': 'Обменять по Trade-in',
                'и используйте это время для принятия решения': 'и используйте это время для принятия решения',
                'на 24 часа': 'на 24 часа',
                'Забронируйте авто': 'Забронируйте авто',
                'Запишитесь': 'Запишитесь',
                'Обменять': 'Обменять',
                'на другой с нашей или вашей доплатой': 'на другой с нашей или вашей доплатой',
                'обменяем ваш автомобиль': 'обменяем ваш автомобиль',
                'совершите бесплатную тестовую поездку': 'совершите бесплатную тестовую поездку',
                'на тест-драйв': 'на тест-драйв',
            },
            uk: {
                'Записаться': 'Записатись',
                'Забронировать': 'Забронювати',
                'Запись на тест-драйв': 'Запис на тест-драйв',
                'Забронировать на 24 часа': 'Забронювати на 24 години',
                'Забронируйте свой будущий автомобиль прямо сейчас': 'Забронюйте свій майбутній автомобіль прямо зараз',
                'Запись на обмен': 'Запис на обмін',
                'Обменять по Trade-in': 'Обміняти по Trade-in',
                'и используйте это время для принятия решения': 'і використайте цей час на прийняття рішення',
                'на 24 часа': 'на 24 години',
                'Забронируйте авто': 'Забронюйте авто',
                'на другой с нашей или вашей доплатой': 'на інший з нашою чи вашою доплатою',
                'Обменять': 'Обміняти',
                'Запишитесь': 'Запишіться',
                'обменяем ваш автомобиль': 'обміняємо ваш автомобіль',
                'совершите бесплатную тестовую поездку': 'здійсніть безкоштовну тестову поїздку',
                'на тест-драйв': 'на тест-драйв',
            }
        }
    }
}
