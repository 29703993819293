export default {
    name: 'Technical',
    props: {
        report: Object,
        valuations: Object,
    },
    components: {
        BlockTitle: require('../Common/BlockTitle/index.vue').default,
    },
    computed: {
        valuation() {
            return this.valuations[this.report.valuation]
        }
    },
    i18n: {
        messages: {
            ru: {
                'Техническое состояние': 'Техническое состояние',
                'Двигатель': 'Двигатель',
                'Другие замечания': 'Другие замечания',
                'Мы отметили элементы, требующие ремонта или замены': 'Мы отметили элементы, требующие ремонта или замены',
            },
            uk: {
                'Техническое состояние': 'Технічний стан',
                'Двигатель': 'Двигун',
                'Другие замечания': 'Інші зауваження',
                'Мы отметили элементы, требующие ремонта или замены': 'Ми відмітили елементи, які вимагають ремонту або заміни',
            }
        }
    }
}