import _laxios from '../_laxios';
import Vue from 'vue';
import bookmarks from './bookmarks';

module.exports = {
    namespaced: true,
    modules: {
        bookmarks,
    },
    state: Object.assign({
        id: 0,
        lot: {},
        popup: false,
        requestPopup: {
            title: '',
            btnText: '',
            type: '',
        },
        loggerData: {},
        popupData: {}
    }, __INITIAL_STATE__.marketplace),
    actions: {
        id({ commit }, id) {
            commit('id', id);
        },
        lot({ commit, getters, rootGetters }, { cookies, id }) {
            const { 'lang/id': langId } = rootGetters;
            let headers;
            if (cookies) {
                headers = { Cookie: cookies };
            }

            return _laxios
                .marketplaceGet
                .expandUrl({ id })
                .request({ responseType: 'json', headers, params: { langId } })
                .then((data) => {
                    commit('lot', data);
                    return data;
                })
        },
        passport({ rootGetters }, vin) {
            const { 'lang/id': langId } = rootGetters;
            return _laxios
                .marketplacePassport
                .expandUrl({ vin })
                .request({ responseType: 'json', params: { langId } });
        },
        report({ getters, rootGetters }, { id, hash }) {
            const { 'lang/id': langId } = rootGetters;
            return _laxios
                .marketplaceReport
                .expandUrl({ id })
                .request({ responseType: 'json', params: { langId, hash } })
        },
        addRequest({ getters, rootGetters }, { type, name, phone }){
            const { id } = getters;
            return _laxios
                .marketplaceRequest
                .expandUrl({ id, type })
                .request({ responseType: 'json', data: { name, phone } })
        }
    },
    mutations: {
        id(state, id) {
            Vue.set(state, 'id', id);
        },
        lot(state, payload) {
            Vue.set(state, 'lot', payload);
        },
        popup(state, status) {
            Vue.set(state, 'popup', status);
        },
        requestPopup(state, payload) {
            Object.assign(state.requestPopup, payload);
        },
        requestStatus(state, { name, status }) {
            Vue.set(state.lot.requests, name, status);
        },
        loggerData(state, payload) {
            Vue.set(state, 'loggerData', payload);
        },
        popupData(state, payload) {
            Vue.set(state, 'popupData', payload);
        }
    },
    getters: {
        requestPopup({ requestPopup }) {
            return requestPopup;
        },
        id({ id }) {
            return id;
        },
        lot({ lot }) {
            return lot;
        },
        popup({ popup }) {
            return popup;
        },
        loggerData({ loggerData }) {
            return loggerData;
        },
        popupData({ popupData }) {
            return popupData;
        }
    }
};
