export default {
    name: 'Seller',
    i18n: {
        messages: {
            ru: {
                'Продавец': 'Продавец'
            },
            uk: {
                'Продавец': 'Продавець'
            }
        }
    }
}