import { mapMutations } from 'vuex';

export default {
    props: {
        show: Boolean,
        formatted: String,
        number: String,
        sticky: Boolean
    },
    name: 'Phone',
    methods: {
        ...mapMutations({
            loggerData: 'marketplace/loggerData'
        }),
        showNumber(event) {
            if(!this.isMobile) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.loggerData({
                event_id: this.isMobile ? 334 : 21,
                phone_position: this.sticky ? 4 : 1
            });
            this.$emit('showNumber');
        }
    }
}
