import moment from 'moment';

const PLACEHOLDER = 'ххх хх хх';

export default {
    name: 'Manager',
    components: {
        Phone: require('./Phone/index.vue').default,
    },
    props: {
        info: Object,
        sticky: Boolean,
    },
    mounted() {
        window.notSticky = 1800;
        window.addEventListener('scroll', () => {
            if (this.$refs.manager && !this.sticky) {
                const top = this.$refs.manager.getBoundingClientRect().top;
                if(top) {
                    window.notSticky = top;
                }
            } else if(this.$refs.manager && this.sticky) {
                this.showSticky = window.notSticky > this.$refs.manager.getBoundingClientRect().bottom + 100;
            }
        })
    },
    data() {
        return {
            show: false,
            showSticky: true,
        }
    },
    computed: {
        number() {
            // (063) ххх хх хх
            const { phone: { formatted } } = this.info;
            if (this.show) return formatted;
            return formatted.replace(/\d{3} \d{2} \d{2}/gm, PLACEHOLDER);
        },
        shortName() {
            return this.info.name.split(' ')[0];
        },
        visit() {
            return moment(this.info.visit.date).locale(this.langCode).fromNow();
        }
    },
    methods: {
        showNumber() {
            this.show = true;
        },
    },
    i18n: {
        messages: {
            ru: {
                'Менеджер по продажам': 'Менеджер по продажам',
                'Менеджер': 'Менеджер',
                'На сайте был': 'На сайте был',
                'Сейчас на сайте': 'Сейчас на сайте',
                'Написать в чат': 'Написать в чат',
            },
            uk: {
                'Менеджер по продажам': 'Менеджер з продажу',
                'Менеджер': 'Менеджер',
                'На сайте был': 'На сайті був',
                'Сейчас на сайте': 'Зараз на сайті',
                'Написать в чат': 'Написати в чат',
            }
        }
    }
}