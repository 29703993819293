export default {
    name: 'Title',
    data() {
        return {
            hidePopup: false,
        }
    },
    methods: {
        close() {
            this.hidePopup = false;
        },
        open() {
            this.hidePopup = true;
        },
    },
    i18n: {
        messages: {
            ru: {
                'проверил': 'проверил',
                'Не критичные, но важные детали': 'Не критичные, но важные детали',
                'Стоит обратить внимание': 'Стоит обратить внимание',
                'Закрыть': 'Закрыть',
                'То, что нужно исправить в первую очередь': 'То, что нужно исправить в первую очередь',
                'Критические замечания': 'Критические замечания',
                'Хорошее состояние': 'Хорошее состояние',
                'Хорошо': 'Хорошо',
                'Идеальное состояние': 'Идеальное состояние',
                'Отлично': 'Отлично',
                'Классификация технического состояния': 'Классификация технического состояния',
            },
            uk: {
                'проверил': 'перевірив',
                'Не критичные, но важные детали': 'Не критичні, але важливі деталі',
                'Стоит обратить внимание': 'Варто звернути увагу',
                'Закрыть': 'Закрити',
                'То, что нужно исправить в первую очередь': 'Те, що треба виправити в першу чергу',
                'Критические замечания': 'Критичні зауваження',
                'Хорошее состояние': 'Гарний стан',
                'Хорошо': 'Добре',
                'Идеальное состояние': 'Ідеальний стан',
                'Отлично': 'Відмінно',
                'Классификация технического состояния': 'Классифікація технічного стану',
            }
        }
    }
}