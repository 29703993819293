export default {
    name: 'Interior',
    props: {
        report: Object,
        valuations: Object,
    },
    components: {
        BlockTitle: require('../Common/BlockTitle/index.vue').default,
    },
    computed: {
        valuation() {
            return this.valuations[this.report.valuation]
        }
    },
    i18n: {
        messages: {
            ru: {
                'Состояние салона': 'Состояние салона',
                'Все исправно': 'Все исправно',
                'Мы отметили элементы, требующие ремонта или замены': 'Мы отметили элементы, требующие ремонта или замены',
            },
            uk: {
                'Состояние салона': 'Стан салону',
                'Все исправно': 'Все справно',
                'Мы отметили элементы, требующие ремонта или замены': 'Ми відмітили елементи, які вимагають ремонту або заміни',
            }
        }
    }
}