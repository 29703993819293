import { mapMutations } from 'vuex';

export default {
	name: 'Report',
	props: {
		order: Object,
		report: Object,
	},
	components: {
		Title: require('./Title/index.vue').default,
		Classification: require('./Classification/index.vue').default,
		Body: require('./Body/index.vue').default,
		Technical: require('./Technical/index.vue').default,
		Interior: require('./Interior/index.vue').default,
		TestDrive: require('./TestDrive/index.vue').default,
	},
	methods: {
		...mapMutations({
			loggerData: 'marketplace/loggerData'
		}),
		onShowFullReport() {
			this.loggerData({ event_id: 733 });
		}
	},
	i18n: {
		messages: {
			ru: {
				'Смотреть полный отчет': 'Смотреть полный отчет',
			},
			uk: {
				'Смотреть полный отчет': 'Дивитись повний звіт',
			}
		}
	}
};
