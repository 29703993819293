export default {
    name: 'Guarantees',
    i18n: {
        messages: {
            ru: {
                'гарантирует': 'гарантирует',
                'для вас место и время': 'для вас место и время',
                'Бесплатную доставку': 'Бесплатную доставку',
                'Доставим авто в удобное': 'Доставим авто в удобное',
                'Вы сможете вернуть авто в течение недели или до 300 км пробега': 'Вы сможете вернуть авто в течение недели или до 300 км пробега',
                'дней гарантии': 'дней гарантии',
                'центре технических проверок': 'центре технических проверок',
                'Мы проверили состояние авто на сертифицированном': 'Мы проверили состояние авто на сертифицированном',
                'Подтвержденное техническое состояние авто': 'Подтвержденное техническое состояние авто',
            },
            uk: {
                'гарантирует': 'гарантує',
                'для вас место и время': 'для вас місце та час',
                'Доставим авто в удобное': 'Доставимо авто у зручне',
                'Бесплатную доставку': 'Безкоштовну доставку',
                'Вы сможете вернуть авто в течение недели или до 300 км пробега': 'Ви зможете повернути авто протягом тижня або до 300 км пробігу',
                'дней гарантии': 'днів гарантії',
                'центре технических проверок': 'центрі технічних перевірок',
                'Мы проверили состояние авто на сертифицированном': 'Ми перевірили стан авто на сертифікованому',
                'Подтвержденное техническое состояние авто': 'Підтверджений технічний стан авто',
            },
        }
    }

}