import Vue from 'vue';
import _laxios from '../_laxios';

const { marketplace: { bookmarks } = {} } = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		active: false,
		login: false,
		success: false,
	}, bookmarks),
	actions: {
		isActive({ commit }, id) {
			return _laxios
				.auctionGetBookmarks
				.expandUrl({ id })
				.request({
					responseType: 'json',
					params: { content_type_ids: [1, 3], limit: 9999, offset: 0 },
				})
				.then((payload) => payload.some(({ item_id } = {}) => item_id === id))
				.then((hasBookmark) => {
					commit('active', hasBookmark);
					return hasBookmark;
				});
		},
		add({ commit, rootGetters }) {
			const { 'marketplace/id': id, 'marketplace/lot': { currentUser } = {} } = rootGetters;

			if(!currentUser.id) {
				return commit('active', true);
			}

			const data = new FormData();
			data.append('itemId', id);
			data.append('projectId', 1);
			data.append('contentTypeId', 1);
			data.append('key', `user${currentUser.id}`);
			data.append('currentDevice', 'desktop');

			return _laxios
				.auctionAddBookmarks
				.request({data})
				.then(() => commit('active', true));

		},
		delete({ commit, rootGetters }) {
			const { 'marketplace/id': id, 'marketplace/lot': { currentUser } = {} } = rootGetters;
			if(!currentUser.id) {
				return commit('active', false);
			}
			return _laxios
				.auctionDeleteBookmarks
				.request({ params: { itemId: id, key: `user${currentUser.id}` }})
				.then(() => commit('active', false));
		},
	},
	mutations: {
	    active(state, value) {
	        Vue.set(state, 'active', value);
		},
		login(state, value) {
	        Vue.set(state, 'login', value);
		},
		success(state, value) {
			Vue.set(state, 'success', value);
		}
	},
	getters: {
		active({ active }) {
			return active;
		},
		login({ login }) {
			return login;
		},
		success({ success }) {
			return success;
		}
	}
};
