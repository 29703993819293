import { mapGetters } from 'vuex';

export default {
	name: 'Preview',
	props: {
		cursorOuter: Number,
		photos: Array,
		title: String,
	},
	data() {
		return {
			showLimit: 8,
			showAll: false,
		};
	},
	computed: {
		cursor: {
			get(){
				return this.cursorOuter;
			},
			set(value) {
				this.$emit('cursor', value);
			}
		},
	    ...mapGetters({
			// title: 'auction/altTitle',
			// cursor: 'auction/photos/cursor',
			// photos: 'auction/photos/photos',
		}),
		items() {
	        return this.photos;
		},
		count() {
			return this.photos.length;
		},
	},
	methods: {
		changePhoto(index) {
			this.cursor = index;
		}
	},
	i18n: {
		messages: {
			ru: {
				'Смотреть все': 'Смотреть все',
				'фотографий': 'фотографий',
				'Скрыть': 'Скрыть',
			},
			uk: {
				'Смотреть все': 'Дивитися всі',
				'фотографий': 'фотографій',
				'Скрыть': 'Приховати',
			}
		}
	}
};
