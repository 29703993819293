export default {
	name: 'MegaPhoto',
	mixins: [require('../../../../../mixins/humanize')],
	props: {
		title: String,
		price: Number,
		photos: Array,
		manager: Object,
		city: Object,
	},
	data() {
		return {
			show: true,
		};
	},
	components: {
		Carusel: require('./Carusel/index.vue').default,
		MegaPhotoManager: require('./MegaPhotoManager/index.vue').default,
	},
	computed: {
		priceHumanized() {
			return this.humanizeNumber(this.price);
		},
	},
	methods: {
		close() {
			this.$emit('megaphoto', false);
		}
	}
};
