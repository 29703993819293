export function clearURL(str) {
	return str.replace('https://auto.ria.com', '').replace('/uk/', '/');
}

export function formPathByIdClickElement(path) {
	let pathStr = '';
	if (Array.isArray(path) && path.length > 0) {
		path.map(item => {
			if (item.id) {
				pathStr += pathStr.length ? '_' + item.id : item.id;
			}
		});
	}
	return pathStr;
}

export function formDescriptionClickElement(target) {
	const parentNodeTag = ['SPAN', 'IMG', 'STRONG'];
	let parent1, parent2, child1;
	let descStr = target.tagName;
	if (target.id) descStr += '_' + target.id;
	if (target.value) descStr += '_' + target.value;
	switch (true) {
		case target.tagName === 'A' && target.href && target.href.length > 0:
			descStr = '';
			descStr += target.href;
			break;
		case Boolean(parentNodeTag.includes(target.tagName) && target.parentNode):
			if (target.parentNode.href && target.parentNode.href.length > 0) {
				descStr = '';
				descStr += target.parentNode.href;
			} else if (target.parentNode.id) {
				descStr = '';
				descStr += target.parentNode.id;
			}
			break;
		case target.tagName === 'SELECT' && target.title && target.title.length > 0:
			descStr += '_' + target.title;
			break;
		case target.tagName === 'BUTTON' && target.innerText && target.innerText.length > 0:
			descStr += '_' + target.innerText;
			break;
		case target.tagName === 'INPUT' && target.placeholder && target.placeholder.length > 0:
			descStr += '_' + target.placeholder;
			break;
		case target.tagName === 'use' && target.href && target.href.baseVal && target.href.baseVal.length > 0:
			descStr += '_' + target.href.baseVal;
			break;
		case target.tagName === 'svg':
			child1 = target.firstChild;
			parent1 = target.parentNode;
			if (child1.tagName === 'use' && child1.href && child1.href.baseVal && child1.href.baseVal.length > 0) {
				descStr += '_' + child1.href.baseVal;
			} else if (parent1 && parent1.id && parent1.id.length > 0) {
				descStr = '';
				descStr += parent1.tagName + '_' + parent1.id;
			} else if (parent1 && parent1.href && parent1.href.length > 0) {
				descStr = '';
				descStr += parent1.href;
			}
			break;
		case target.tagName === 'path':
			descStr = '';
			parent1 = target.parentNode;
			parent2 = target.parentNode.parentNode;
			if (parent1 && parent1.id && parent1.id.length > 0) {
				descStr += parent1.tagName + '_' + parent1.id;
			} else if (parent2 && parent2.id && parent2.id.length > 0) {
				descStr += parent2.tagName + '_' + parent2.id;
			} else if (parent2 && parent2.href && parent2.href.length > 0) {
				descStr += parent2.href;
			}
			break;
	}
	return descStr;
}

