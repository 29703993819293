/* eslint-disable global-require,import/no-extraneous-dependencies */
import { mapGetters, mapActions } from 'vuex';
import storeModule from '../../store/marketplace';
import { clearURL } from '../../helpers/userNavigation';

const seo = {
  uk: {
    в: 'у',
    Цена: 'Ціна',
    двигатель: 'двигун',
    цвета: 'кольору',
    пробег: 'пробіг',
    'тыс. км.': 'тис. км.',
  },
  ru: {
    в: 'в',
    Цена: 'Цена',
    двигатель: 'двигатель',
    цвета: 'цвета',
    пробег: 'пробег',
    'тыс. км.': 'тыс. км.',
  },
};

export default {
  name: 'Marketplace',
  mixins: [require('../../mixins/slonDataLogger')],
  mounted() {
    this.marketplaceLogger({ event_id: 20 });
  },
  init({ store, context, route }) {
    const { to: { params: { id } = {} } = {} } = {} = route;
    store.registerModule('marketplace', storeModule);

    let cookies = {};
    if (context) {
      cookies = context.request.headers.cookie;
    }
    store.dispatch('marketplace/id', id);
    return store
      .dispatch('marketplace/lot', { cookies, id })
      .catch((error) => {
        const { status, data = {} } = error.response || {};
        if (status === 404) {
          return context.response.redirect(`/auto___${data.id}.html`);
        }
        console.error('GET [marketplace/lot] ERROR:', error.toString());
      });
  },
  metaInfo() {
    const { lot, langCode } = this;
    const _seo = seo[langCode];

    let title = 'AUTO.RIA – Продам';
    let description = 'Продаю на AUTO.RIA';

    if (lot && lot.id) {
      title = [
        title,
        lot.brand.slang, lot.model.slang, lot.version,
        lot.fuel.name, lot.engine.volume, lot.body.name,
        'бу', _seo['в'], `${lot.city.genitive},`,
        _seo['Цена']?.toLowerCase(), `${lot.price.USD}$`,
      ].join(' ');

      description = [
        description,
        lot.brand.name, lot.model.name, lot.version, `${lot.year},`,
        lot.body.name, lot.fuel.name, lot?.color?.genitive?.toLowerCase(),
        `${_seo['цвета']}`, `${_seo['двигатель']}`, lot?.engine?.volume,
        `${lot.fuel?.name?.toLowerCase()},`, _seo['пробег'], lot.race,
        _seo['тыс. км.'], `${_seo['Цена']}:`, `${lot.price.USD}$`,
        'Продавец Маркетплейс AUTO.RIA',
      ].join(' ');
    }
    return {
      title,
      meta: [
        { name: 'description', content: description },
        { property: 'og:site_name', content: 'AUTO.RIA' },
        { property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/marketplace/' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/marketplace/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/marketplace/' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/marketplace/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/marketplace/' },
        { rel: 'canonical', href: `https://auto.ria.com${this.langPrefix}/marketplace/${this.id}.html` },
      ],
      bodyAttrs: {
        class: this.popup ? 'modal-open page-overflow' : '',
      },
    };
  },
  components: {
    Popup: () => import('../Common/Popup.vue'),
    InnerPopup: require('./Common/InnerPopup/index.vue').default,
    Main: require('./Main/index.vue').default,
    LeftBar: require('./LeftBar/index.vue').default,
  },
  methods: {
    ...mapActions({
      addBookmarks: 'marketplace/bookmarks/add',
      deleteBookmarks: 'marketplace/bookmarks/delete',
    }),
    subscribe() {
      if (this.activeBookmark) {
        this.deleteBookmarks();
      } else {
        this.addBookmarks();
        this.marketplaceLogger({ event_id: 351 });
      }
    },
    marketplaceLogger(extendData = {}) {
      try {
        const defaultData = {
          proposal_id: this.id,
          previous_url: document.referrer ? encodeURIComponent(clearURL(document.referrer)) : '',
          ria_options: 1,
        };
        if (Object.keys(extendData).length) Object.assign(defaultData, extendData);
        this._slonik(defaultData);
      } catch (err) {
        console.error('=== ERROR in marketPlaceLogger ===', err.toString());
      }
    },
  },
  computed: {
    ...mapGetters({
      id: 'marketplace/id',
      popup: 'marketplace/popup',
      lot: 'marketplace/lot',
      loggerData: 'marketplace/loggerData',
      popupData: 'marketplace/popupData',
      requestPopup: 'marketplace/requestPopup',
      activeBookmark: 'marketplace/bookmarks/active',
    }),
  },
  watch: {
    loggerData(value) {
      if (Object.keys(value).length) {
        this.marketplaceLogger(value);
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        в: 'в',
        цена: 'цена',
        'Добавить объявление в избранное': 'Добавить объявление в избранное',
      },
      uk: {
        в: 'у',
        цена: 'ціна',
        'Добавить объявление в избранное': 'Додати оголошення в обране',
      },
    },
  },
};
